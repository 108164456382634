<template>
  <div class="box">
    <div class="infos">
      <div class="titles">
        <div class="left">
          <span :class="tabsIndex == '' ? 'active' : ''" @click=";(tabsIndex = ''), getList()">全部</span>
          <span :class="tabsIndex == 1 ? 'active' : ''" @click=";(tabsIndex = 1), getList()">待审核</span>
          <span :class="tabsIndex == 2 ? 'active' : ''" @click=";(tabsIndex = 2), getList()">已通过</span>
          <span :class="tabsIndex == 3 ? 'active' : ''" @click=";(tabsIndex = 3), getList()">失败</span>
        </div>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
        <el-table-column prop="domain" label="提交域名" align="center"></el-table-column>
        <el-table-column prop="add_time" label="提交时间" align="center">
          <span slot-scope="scope">
            {{ scope.row.add_time | date('Y-m-d H:i') }}
          </span>
        </el-table-column>
        <el-table-column prop="username" label="账号" align="center"></el-table-column>
        <el-table-column prop="phone" label="电话" align="center"></el-table-column>
        <el-table-column prop="state" label="审核状态" align="center">
          <span :class="scope.row.state == 2 ? 'yes' : 'yes no'" slot-scope="scope">{{ scope.row.state == 1 ? '待审核' : scope.row.state == 2 ? '审核通过' : '失败拒绝' }}</span>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <div class="button">
              <span @click="reset(scope.row, 2)">通过</span>
              <span @click="reset(scope.row, 3)">拒绝</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <el-pagination :page-sizes="[10, 20, 30, 40]" :page-size="10" :pager-count="4" layout="total, sizes, prev, pager, next, jumper" :total="400"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  components: {},
  data() {
    return {
      tabsIndex: 0,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.list = []
      const res = await http.POST('/jw-admin', {
        module: 'settings',
        operation: 'getDomainList',
        state: this.tabsIndex
      })
      this.list = res
    },
    // 修改状态
    changeStatus(e, index) {
      this.list[index].status = !e.status
      this.$message.success('修改成功！')
    },
    // 编辑
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.formDialog.formVisible = true
    },
    // 审核
    async reset(row, index) {
      const res = await http.POST('/jw-admin', {
        module: 'settings',
        operation: 'updateDomain',
        id: row._id,
        state: index
      })
      if (res.status == 1) {
        this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success'
        })
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead {
  th.el-table__cell {
    background: #ecf1f6;
  }
  color: #20303f !important;
}
::v-deep tbody {
  tr:nth-child(2n) {
    background: #fafbfc;
  }
}
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f4f6f8;
  .infos {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    .titles {
      display: flex;
      justify-items: center;
      margin-bottom: 20px;
      .left {
        width: calc(100% - 200px);
        span {
          display: inline-block;
          height: 36px;
          padding: 0 22px;
          margin-right: 10px;
          line-height: 36px;
          color: #20303f;
          text-align: center;
          cursor: pointer;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          margin-bottom: 10px;
        }
        .active {
          color: #fefefe;
          background: #6954f0;
        }
      }
      .right {
        display: flex;
        .button {
          width: max-content;
          height: 36px;
          margin-right: 14px;
          border-radius: 4px;
          padding: 0 14px;
          background-color: #ff6a6b;
          cursor: pointer;
          span {
            font-size: 14px;
            line-height: 36px;
            color: #fff;
          }
          &:hover {
            opacity: 0.9;
          }
        }
        .search {
          width: 200px;
          height: 36px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding: 1px 11px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          i {
            display: inline-flex;
            white-space: nowrap;
            flex-shrink: 0;
            flex-wrap: nowrap;
            text-align: center;
            color: #a8abb2;
            transition: all 0.3;
            pointer-events: none;
            margin-right: 8px;
          }
        }
        .el-input__inner {
          width: 100%;
          height: 36px;
          flex-grow: 1;
          color: #606266;
          padding: 0;
          border: none;
        }
      }
    }
    .el-table {
      border-radius: 5px;
      box-shadow: 0 4px 14px 1px rgb(54 124 253 / 8%);
      .el-table__header-wrapper {
        border-radius: 5px 5px 0 0;
      }
      font-size: 14px;
      font-weight: 400;
      color: #6b7c8c;
      .yes {
        display: inline-block;
        height: 32px;
        padding: 0 14px;
        font-size: 13px;
        line-height: 32px;
        cursor: pointer;
        border-radius: 3px;
        color: #59ab31;
        background: #f0f9eb;
        border: 1px solid #e1f3d8;
      }
      .no {
        color: #f56c6c;
        background: #fef0f0;
        border: 1px solid #fde2e2;
      }
      .button {
        display: flex;
        justify-content: center;
        > span {
          margin-right: 14px;
          color: #6954f0;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}
</style>
